* {
    box-sizing: border-box;
}
body {
    margin: 0;
    background-color: #f9f9f9;
    .page-container{
        background-color: #f9f9f9;
        height: 100vh;
        width: 100%;
        display: flex;
    }
}

a[href^="https://blogger.googleusercontent.com"] {
    margin: 0 !important
}

@media (max-width: 1080px) {
    body .page-container {
        flex-direction: column;
        height: 100%;
    }  
}
@media (max-width: 820px) {
    body .page-container {
        flex-direction: column;
        height: 100%;
    }
}