#posts {
    overflow: scroll;
    width: 70%;
    padding: 30px 20% 30px 30px;
    transition: opacity .3s, transform .3s;
    background: #f9f9f9;
    z-index: 3;
    &.shown {
        animation: appear .3s;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .loading-posts {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        color: white;
        font-size: 22px;
        opacity: 0.5;
    }
}

@media (max-width: 1080px) {
    #posts {
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: scroll;
        width: 100%;
        padding: 30px 60px 60px 60px;
        padding-top: 0;
    }  
}

@media (max-width: 820px) {
     #posts {
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: scroll;
        width: 100%;
        padding: 30px 30px 30px 30px;
        padding-top: 0;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
  }