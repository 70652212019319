.menu {
    width: 100%;
    max-width: min-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    font-family: faceless;
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-top: 30px;
    margin: 0px 0px 30px 0px;
    opacity: 0;
    z-index: 1;
    flex-direction: column;
    &.opened {
       opacity: 1;
    }
    > div, > a {
        text-decoration: none;
        padding: 10px;
        margin: 0 10px;
        cursor: pointer;
        color: #d7d7d7;
        transition: color .3s;
        &.selected,&:hover {
            color: #000000bf;
        }
    }
}

@media (max-width: 1560px) {
    .menu {
        font-size: 3em;
    }
}
@media (max-width: 1080px) {
    .menu {
        max-width: unset;
        display: flex;
        flex-direction: row;
        font-size: 4em;
        flex-direction: row;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        margin-top: -120px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
  }