#banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    position: sticky;
    top: 0;
    background: #f9f9f9;
    cursor: pointer;
    z-index: 2;
    > svg {
        z-index: 2;
    }
    .cover-background {
        position: absolute;
        width: 100%;
        display: flex;
        height: 800px;
        background: #f9f9f9;
        bottom: 140px;
        z-index: 1;
    }
}

@media (max-width: 1560px) {
    #banner > svg {
            height: 140px;
            width: 140px;
            margin-bottom: 20px;
            > image{
                height: 140px;
                width: 140px;
            }
    }
}

@media (max-width: 1080px) {
    #banner {
        padding-top: 30px;
        padding-left: unset;
        padding : 30px;
        margin-bottom: 0;
        > svg {
            height: 140px;
            width: 140px;
            margin-bottom: 0;
            margin-top: 50px;
            > image{
                height: 140px;
                width: 140px;
            }
        }
    }
}

@media (max-width: 820px) {
    #banner {
        padding-top: 30px;
        height: 170px;
        padding-left: unset;
        padding: 30px;
        margin-bottom: 0;
        > svg {
            height: 100px;
            width: 100px;
            margin-bottom: 0;
            margin-top: 50px;
            > image{
                height: 100%;
                width: 100%;
            }
        }
    }
}