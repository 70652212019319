.post {
    background-color: #f9f9f9;
    padding: 30px 50px;
    border-radius: 8px;
    margin-bottom: 18px;
    font-weight: 100;
    width: 100%;
    overflow-wrap: break-word;
    display: grid;
    > h2 {
        margin-bottom: 0;
        font-family: canterbury;
        font-size: 30px;
    }
    > span.date {
        font-size: 14px;
        color: #acacac;
        font-family: canterbury;

        .hours {
            color: #e3e3e3;
        }
    }
    > .post-content {
        font-family: Inter var experimental, Inter var, Inter, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Apple Color emoji;
        font-size: .9em;
        line-height: 1.75;
        -webkit-font-smoothing: antialiased;
    }
    &.scripture {
        // box-shadow: #f6e27a 1px 1px 12px;
        transition: box-shadow 1s ease-out;
        animation: boxShadowAnimation 2s infinite alternate;
        > span.date {
            color: #d1bf61;
        }
        > .post-content {
            color: rgb(203, 182, 108);
            font-weight: 200;
        }             
        h2 {
            background-image: linear-gradient(
                to right,
                #462523 0,
                #cb9b51 22%, 
                #f6e27a 45%,
                #f6f2c0 50%,
                #f6e27a 55%,
                #cb9b51 78%,
                #462523 100%
            );
            color: transparent;
            background-clip: text; /* Standard syntax */
            -webkit-background-clip: text; /* WebKit (Safari/Chrome/Blink) */
            -moz-background-clip: text; /* Mozilla Firefox */
            -ms-background-clip: text; /* Internet Explorer */
        }
    }
}

@media (max-width: 1080px) {
    .post {
        width: 100%;
    }
}

@media (max-width: 820px) {
    .post {
        width: 100%;
    }
}

// @keyframes boxShadowAnimation {
//     from {
//         box-shadow: #f6e27a 1px 1px 2px;
//     }
//     to {
//         box-shadow: #f6e27a 1px 1px 12px;
//     }
//   }